<template>
  <div class="">
    <el-dialog
      :title="type==1?'微信支付':'支付宝支付'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="600px"
      @close="closeDialog"
    >
      <div class="charge_content">
        <div
          v-if="errorText"
          class="error_box"
        >{{errorText}}</div>
        <div
          v-else
          class="success_box"
        >
          <p style="font-size:18px;font-weight:700;">
            请使用{{type==1?'微信':'支付宝'}}扫描下方二维码付款</p>
          <div
            v-loading="loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            ref="qrCodeDiv"
            class="qrCode_box"
          ></div>
          <p
            style="margin-bottom:3px;font-size:20px;color:#4877E8;"
            v-show="payInfo.pay_fee"
          >
            ￥{{payInfo.pay_fee}}
          </p>
          <p
            style="font-size:16px;color:#4877E8;"
            v-show="payInfo.pay_rate"
          >
            微信支付需付{{payInfo.pay_rate*100}}%手续费
          </p>
          <p style="margin-bottom:5px;">付款成功前请不要关闭此页面</p>
          <p>付款成功后，请根您的情况点击下方按钮</p>
          <el-button
            type="primary"
            @click="clickPaySuccess"
          >付款成功</el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { chargeTransactionsApi } from '../../api/user'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      loading: false,
      dialogVisible: false,
      type: 1,
      errorText: '',
      price: '',
      payInfo: {}
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () { },
  mounted () { },
  // 组件方法
  methods: {
    async openDialog (val) {
      this.dialogVisible = true
      this.type = val.pay_service
      this.price = val.charge_fee
      this.loading = true
      const res = await chargeTransactionsApi(val)
      console.log(res)
      this.loading = false
      if (res && res.code === 0) {
        this.$nextTick(() => {
          this.payInfo = res.data
          this.bindQRCode(res.data.qrcode_url)
        })
      } else {
        this.errorText = res ? res.msg : '未获取到支付信息，请尝试重新打开'
      }
    },
    bindQRCode (val) {
      // eslint-disable-next-line no-new
      new QRCode(this.$refs.qrCodeDiv, {
        text: val, // 二维码的内容
        width: 150, // 二维码的宽度
        height: 150, // 二维码的高度
        colorDark: '#333333', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: QRCode.CorrectLevel.H// 容错率，L/M/H
      })
      // console.log(res)
    },
    closeDialog () {
      if (this.$refs.qrCodeDiv) {
        this.$refs.qrCodeDiv.innerHTML = ''
      }
      this.errorText = ''
      this.payInfo = {}
    },
    clickPaySuccess () {
      this.dialogVisible = false
      this.$store.dispatch('getUserInfo', 1)
    }

  }
}
</script>

<style scoped lang="less">
.el-dialog {
  min-width: 500px;
}
.charge_content {
  height: 380px;
  text-align: center;
  .error_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .success_box {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  p {
    margin-bottom: 15px;
  }
  .qrCode_box {
    height: 150px;
    width: 150px;
    margin-bottom: 15px;
  }
}
</style>
